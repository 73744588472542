import axios from 'axios'

let host;

if(process.env.NODE_ENV=="development"){
    //开发环境
    host="http://yuan.admin.lcpxj.com:8080";
}else if(process.env.NODE_ENV=="production"){
    //生产环境
    host="http://yuan.admin.lcpxj.com:8080";
}

const request = axios.create({
    baseURL:host,
    timeout:5000
})

//请求拦截器
request.interceptors.request.use(config=>{
    return config;
},error=>{
    return Promise.reject(error);
})

//响应拦截器
request.interceptors.response.use(result=>{
    return result;
},error=>{
    return Promise.reject(error);
})

export default request;