import Vue from 'vue'
import VueRouter from 'vue-router'

//编程式导航重复跳转同一路由报错bug解决
let push = VueRouter.prototype.push;
let replace = VueRouter.prototype.replace;
//重写VueRouter.prototype原型对象的push|replace方法
VueRouter.prototype.push = function (location) {
    //调用push方法且篡改push方法内部this指向->vueRouter类的实例
    push.call(this, location, () => { }, () => { });
}
VueRouter.prototype.replace = function (location) {
    //调用push方法且篡改push方法内部this指向->vueRouter类的实例
    replace.apply(this, [location, () => { }, () => { }]);
}

Vue.use(VueRouter);

export default new VueRouter({
    routes:[
        {
            path:'/',
            meta:{
              title:"篆刻"
            },
            redirect:'/index'
        },
        {
            path:'/index',
            name:'index',
            meta:{
                title:"篆刻鉴赏",
            },
            component:()=>import('@/views/index/index.vue')
        },
        {
            path:'/seal',
            name:'index',
            meta:{
                title:"印章列表",
            },
            component:()=>import('@/views/seal/index.vue')
        }
    ]
})