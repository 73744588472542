import request from "./request";

//获取随机印章图
export function getSonRand(query) {
    return request({
        url: '/index/api/sonRand',
        method: 'get',
        params: query
    })
}
//获取印章列表
export function getSealList(query) {
    return request({
        url: '/index/api/son',
        method: 'post',
        params:{
            pageSize:query.pageSize,
            pageNum:query.pageNum
        },
        data:{
            name:query.name
        }

    })
}