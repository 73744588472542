<template>
  <div>
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'app',
  data() {
    return {header_show:true, }
  },
  methods:{

  },
  components: {

  },
}
</script>

<style>

</style>
