import Vue from 'vue'
import 'reset-css'; //清除默认样式
import App from './App.vue'
import './plugins/element.js'
import router from '@/router'
import store from '@/store'
import * as api from '@/request/api.js'
import   'animate.css';
import VueLazyload from 'vue-lazyload'

Vue.use(VueLazyload, {
  // 可选配置项，可省略
  loading: require('./views/assets/static/loading.gif'), // 加载中时显示的图片
  preLoad: 1.3, // 预加载高度的比例
  attempt: 3 // 尝试加载次数
});
// 滚动动画 wow.js
import {
  WOW
} from 'wowjs'
Vue.prototype.$wow = new WOW({
  boxClass: 'wow', // default
  animateClass: 'animated', // default
  offset: 50, // default
  mobile: true, // default
  live: false,

  // live为true时，控制台会提示：MutationObserver is not supported by your browser. & WOW.js cannot detect dom mutations, please call .sync() after loading new content.

  callback: function (box) {
    console.log("WOW: animating <" + box.tagName.toLowerCase() + ">")
  }
});
Vue.config.productionTip = false
/* 路由发生变化修改页面title */
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
new Vue({
  render: h => h(App),
  router,store,
  beforeCreate(){
    Vue.prototype.$api = api; //各个组件使用this.$api.方法名调用接口
  }
}).$mount('#app')
